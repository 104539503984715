import React from 'react';
import * as XLSX from 'xlsx';
import { Button, useTheme } from '@mui/material';

function DownloadProjectTaskList({ tasks, project }) {
  const theme = useTheme(); // Access the current theme

  const flattenTasks = (tasks, level = 0, parentName = '') => {
    return tasks.reduce((acc, task) => {
      const fullTaskName = parentName ? `${parentName} > ${task.name}` : task.name;
      acc.push({
        Name: fullTaskName,
        Details: task.details || '',
        Status: task.status || '',
        Level: level,
        Completed: task.status === 'Completed' ? 1 : 0,
      });

      if (task.children && task.children.length > 0) {
        acc = acc.concat(flattenTasks(task.children, level + 1, fullTaskName));
      }

      return acc;
    }, []);
  };

  const handleDownload = () => {
    if (!project || tasks.length === 0) {
      console.error('No project or tasks to download');
      return;
    }

    const flattenedTasks = flattenTasks(tasks);

    const totalTasks = flattenedTasks.length;
    const completedTasks = flattenedTasks.filter(task => task.Completed === 1).length;
    const completionPercentage = totalTasks === 0 ? 0 : Math.round((completedTasks / totalTasks) * 100);

    const taskData = flattenedTasks.map(task => ({
      'Task Name': task.Name,
      Details: task.Details,
      Status: task.Status,
      'Task Level': task.Level,
    }));

    const worksheet = XLSX.utils.json_to_sheet(taskData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tasks');

    XLSX.utils.sheet_add_aoa(worksheet, [
      [`Project: ${project.title}`],
      [`Description: ${project.description}`],
      [`Status: ${project.status}`],
      [`Start Date: ${project.startDate}`],
      [`End Date: ${project.endDate}`],
      [`Completion Percentage: ${completionPercentage}%`],
    ], { origin: 'A1' });

    XLSX.writeFile(workbook, `${project.title}_Tasks.xlsx`);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      sx={{
        mt: .5, // Add margin-top for spacing
        backgroundColor: theme.palette.primary.main, // Ensure it adheres to the theme's primary color
        '&:hover': {
          backgroundColor: theme.palette.primary.dark, // Adjust hover color to match theme
        },
      }}
    >
      Download Project Task List
    </Button>
  );
}

export default DownloadProjectTaskList;
