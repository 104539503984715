import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function AircraftManagement() {
  const theme = useTheme();
  const { userData } = useAuth(); // Access userData from AuthContext
  const [open, setOpen] = useState(false);
  const [aircraftData, setAircraftData] = useState({
    aircraftNumber: '',
    aircraftType: '',
    aircraftModel: '',
    locationId: '',
    buildingId: '',
  });
  const [aircraftList, setAircraftList] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchAircraft = async () => {
      let q = collection(db, 'aircraft');
      // Filter by buildingId if the user is a Building Admin
      if (userData.role === 'Building Admin') {
        q = query(q, where('buildingId', '==', userData.buildingId));
      }
      const querySnapshot = await getDocs(q);
      const aircraftArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAircraftList(aircraftArray);
    };

    const fetchLocations = async () => {
      const locationsSnapshot = await getDocs(collection(db, 'locations'));
      const locationsData = locationsSnapshot.docs.map(doc => doc.data());
      setLocations(locationsData);
    };

    fetchAircraft();
    fetchLocations();
  }, [userData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAircraftData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await addDoc(collection(db, 'aircraft'), aircraftData);
      setAircraftList([...aircraftList, aircraftData]);
      handleClose();
    } catch (error) {
      console.error('Error adding aircraft: ', error);
    }
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: 'max-content',
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ fontSize: '1.25rem', mb: 2, textAlign: 'left' }}
      >
        Aircraft Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          mb: 2,
          fontSize: '0.875rem',
          padding: '8px 16px',
        }}
      >
        Add Aircraft
      </Button>

      <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }
      }}
    >
      <DialogTitle sx={{ color: theme.palette.text.primary }}>
        Add New Aircraft
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Aircraft Number"
          name="aircraftNumber"
          value={aircraftData.aircraftNumber}
          onChange={handleChange}
          fullWidth
          sx={{ 
            fontSize: '0.875rem', 
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
            },
          }}
        />
        <TextField
          margin="dense"
          label="Aircraft Type"
          name="aircraftType"
          value={aircraftData.aircraftType}
          onChange={handleChange}
          fullWidth
          sx={{ 
            fontSize: '0.875rem',
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
            },
          }}
        />
        <TextField
          margin="dense"
          label="Aircraft Model"
          name="aircraftModel"
          value={aircraftData.aircraftModel}
          onChange={handleChange}
          fullWidth
          sx={{ 
            fontSize: '0.875rem',
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
            },
          }}
        />
        <Select
          margin="dense"
          label="Location ID"
          name="locationId"
          value={aircraftData.locationId}
          onChange={handleChange}
          fullWidth
          sx={{ 
            fontSize: '0.875rem', 
            mt: 1,
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
            },
          }}
        >
          {locations.map(location => (
            <MenuItem key={location.locationId} value={location.locationId} sx={{ fontSize: '0.875rem' }}>
              {location.locationId}
            </MenuItem>
          ))}
        </Select>
        <Select
          margin="dense"
          label="Building ID"
          name="buildingId"
          value={aircraftData.buildingId}
          onChange={handleChange}
          fullWidth
          sx={{ 
            fontSize: '0.875rem', 
            mt: 1,
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
            },
          }}
        >
          {(locations.find(loc => loc.locationId === aircraftData.locationId)?.buildingIds || []).map(buildingId => (
            <MenuItem key={buildingId} value={buildingId} sx={{ fontSize: '0.875rem' }}>
              {buildingId}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" sx={{ fontSize: '0.875rem' }}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" sx={{ fontSize: '0.875rem' }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>

      <TableContainer component={Paper} sx={{ mt: 4, backgroundColor: theme.palette.background.paper }}>
        <Table>
          <TableHead>
            <TableRow>
              {['Aircraft Number', 'Aircraft Type', 'Aircraft Model', 'Location ID', 'Building ID'].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    padding: '8px',
                    fontSize: '0.875rem',
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {aircraftList.map((aircraft) => (
              <TableRow key={aircraft.id}>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  <Link to={`/aircraft/${aircraft.id}/inspections`} style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                    {aircraft.aircraftNumber}
                  </Link>
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>{aircraft.aircraftType}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>{aircraft.aircraftModel}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>{aircraft.locationId}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>{aircraft.buildingId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AircraftManagement;
