import React from 'react';
import { Box, Grid, Paper, useTheme, Typography } from '@mui/material';
import UserManagement from '../components/UserManagement';
import AircraftManagement from '../components/AircraftManagement';
import LocationManagement from '../components/LocationManagement';
import InspectionManagement from '../components/InspectionManagement'; 
import TdManagement from '../components/TdManagement';
import { useAuth } from '../contexts/AuthContext';

function AdminPage() {
  const theme = useTheme(); 
  const { userData, loading } = useAuth(); // Use the custom hook to get user data

  // Handle loading state and null userData
  if (loading) {
    return <Typography variant="body1" sx={{ p: 2 }}>Loading...</Typography>;
  }

  if (!userData) {
    return <Typography variant="body1" sx={{ p: 2 }}>User data not available.</Typography>;
  }

  const isAdmin = userData.role === 'Admin';
  const isBuildingAdmin = userData.role === 'Building Admin';

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        backgroundColor: theme.palette.background.default, 
        color: theme.palette.text.primary,
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={3}>
        
        {/* User Management Section */}
        {isAdmin && (
          <Grid item xs={12} md={5}>
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, md: 3 },
                height: 'max-content',
                minHeight: '320px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <UserManagement />
            </Paper>
          </Grid>
        )}

        {/* Location Management Section */}
        {isAdmin && (
          <Grid item xs={12} md={3}>
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, md: 3 },
                height: 'max-content',
                minHeight: '320px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <LocationManagement />
            </Paper>
          </Grid>
        )}

        {/* Aircraft Management Section */}
        {(isAdmin || isBuildingAdmin) && (
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, md: 3 },
                height: 'max-content',
                minHeight: '320px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <AircraftManagement buildingId={isBuildingAdmin ? userData.buildingId : null} />
            </Paper>
          </Grid>
        )}

        {/* Inspection Management Section */}
        {(isAdmin || isBuildingAdmin) && (
          <Grid item xs={12} md={5}>
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, md: 3 },
                height: 'max-content',
                minHeight: '320px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <InspectionManagement buildingId={isBuildingAdmin ? userData.buildingId : null} />
            </Paper>
          </Grid>
        )}

        {/* TD Management Section */}
        {(isAdmin || isBuildingAdmin) && (
          <Grid item xs={12} md={5}>
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, md: 3 },
                height: 'max-content',
                minHeight: '320px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <TdManagement buildingId={isBuildingAdmin ? userData.buildingId : null} />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AdminPage;
