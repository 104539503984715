import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  useTheme 
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'react-router-dom';

// Existing imports...

function AircraftDetails() {
  const { aircraftId } = useParams();
  const [aircraft, setAircraft] = useState(null);
  const [employees, setEmployees] = useState([]);
  const theme = useTheme(); // Access the current theme

  useEffect(() => {
    const fetchAircraftDetails = async () => {
      const aircraftRef = doc(db, 'aircraft', aircraftId);
      const aircraftSnap = await getDoc(aircraftRef);

      if (aircraftSnap.exists()) {
        const aircraftData = { id: aircraftSnap.id, ...aircraftSnap.data() };
        setAircraft(aircraftData);

        // Fetch employees based on the locationId and buildingId
        fetchEmployees(aircraftData.locationId, aircraftData.buildingId);
      } else {
        console.log('No such document!');
      }
    };

    const fetchEmployees = async (locationId, buildingId) => {
      try {
        const employeesRef = collection(db, 'locations', locationId, 'buildings', buildingId, 'shops');
        const employeesSnapshot = await getDocs(employeesRef);
        let allEmployees = [];

        // Iterate over each shop to gather employees
        for (const shop of employeesSnapshot.docs) {
          const shopEmployeesRef = collection(db, 'locations', locationId, 'buildings', buildingId, 'shops', shop.id, 'employees');
          const shopEmployeesSnapshot = await getDocs(shopEmployeesRef);

          const shopEmployees = shopEmployeesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          allEmployees = [...allEmployees, ...shopEmployees];
        }

        setEmployees(allEmployees);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchAircraftDetails();
  }, [aircraftId]);

  if (!aircraft) return <Typography>Loading...</Typography>;

  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme background color
        color: theme.palette.text.primary, // Theme text color
        minHeight: '100vh', // Ensure full height for the page
      }}
    >
      <Card
        elevation={3}
        sx={{
          backgroundColor: theme.palette.background.paper, // Theme paper background color
          color: theme.palette.text.primary, // Theme text color
          mb: 4, // Margin-bottom for spacing
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom>Aircraft Details</Typography>
          <Typography variant="h6">{aircraft.aircraftNumber}</Typography>
          <Typography color="textSecondary">{aircraft.aircraftType}</Typography>
          <Typography color="textSecondary">{aircraft.aircraftModel}</Typography>
          <Typography color="textSecondary">Location: {aircraft.locationId}</Typography>
          <Typography color="textSecondary">Building: {aircraft.buildingId}</Typography>
        </CardContent>
      </Card>

      {/* Add the button linking to the inspections page */}
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={`/aircraft/${aircraftId}/inspections`}
        sx={{ mb: 4 }}
      >
        Manage Inspections
      </Button>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>Submit a Call</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              options={employees}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              renderInput={(params) => <TextField {...params} label="Employee Name" variant="outlined" />}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Priority</InputLabel>
              <Select label="Priority">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {/* Add options for Priority here */}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Inspector Needed</InputLabel>
              <Select label="Inspector Needed">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {/* Add options for Inspector Needed here */}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="JCN"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField
              label="Job Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item>
                <Button variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="secondary">
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AircraftDetails;
