import React from 'react';
import { useAuth } from '../components/authUtils';
import { Box, Typography, useTheme } from '@mui/material';

function Dashboard() {
  const { user, userData, loading } = useAuth();
  const theme = useTheme();  // Access the current theme

  if (loading) {
    return (
      <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
        <Typography variant="h4">Welcome to OQL</Typography>
        <Typography>Please sign in or sign up to access the dashboard.</Typography>
      </Box>
    );
  }

  if (userData) {
    switch (userData.status) {
      case 'pending':
        return (
          <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <Typography variant="h4">Account Pending</Typography>
            <Typography>Your account is currently pending approval. Please check back later.</Typography>
          </Box>
        );
      case 'denied':
        return (
          <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <Typography variant="h4">Account Denied</Typography>
            <Typography>We're sorry, but your account application has been denied.</Typography>
          </Box>
        );
      case 'approved':
        return (
          <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <Typography variant="h4">Welcome to Your Dashboard</Typography>
            <Typography>Hello, {userData.name}!</Typography>
            <Typography>Role: {userData.role}</Typography>
            <Typography>Status: {userData.status}</Typography>
            {/* Add more dashboard content here based on user role */}
          </Box>
        );
      default:
        return (
          <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <Typography>An error occurred. Please contact support.</Typography>
          </Box>
        );
    }
  }

  return (
    <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
      <Typography>Loading user data...</Typography>
    </Box>
  );
}

export default Dashboard;
