import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, getDocs, collection } from 'firebase/firestore';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  useTheme,
  TextField,
  Paper,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function AircraftInspections() {
  const { aircraftId } = useParams();
  const theme = useTheme();
  const [aircraft, setAircraft] = useState(null);
  const [inspections, setInspections] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [inspectionData, setInspectionData] = useState({
    inspectionId: '',
    dueDate: '',
  });
  const [inspectionTypes, setInspectionTypes] = useState([]);

  useEffect(() => {
    const fetchAircraft = async () => {
      const docRef = doc(db, 'aircraft', aircraftId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAircraft({ id: docSnap.id, ...docSnap.data() });
        setInspections(docSnap.data().inspections || []);
      }
    };

    const fetchInspectionTypes = async () => {
      const querySnapshot = await getDocs(collection(db, 'inspectionTypes'));
      const inspectionsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setInspectionTypes(inspectionsArray);
    };

    fetchAircraft();
    fetchInspectionTypes();
  }, [aircraftId]);

  const handleOpen = (index = null) => {
    setEditMode(index !== null);
    setEditIndex(index);
    if (index !== null) {
      setInspectionData(inspections[index]);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInspectionData({
      inspectionId: '',
      dueDate: '',
    });
  };

  const handleSaveInspection = async () => {
    let newInspections = [...inspections];
    if (editMode) {
      newInspections[editIndex] = inspectionData;
    } else {
      newInspections.push(inspectionData);
    }

    await updateDoc(doc(db, 'aircraft', aircraftId), {
      inspections: newInspections,
    });

    setInspections(newInspections);
    handleClose();
  };

  const handleDeleteInspection = async (index) => {
    const newInspections = inspections.filter((_, i) => i !== index);
    await updateDoc(doc(db, 'aircraft', aircraftId), {
      inspections: newInspections,
    });
    setInspections(newInspections);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInspectionData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
      }}
    >
      {aircraft && (
        <Box>
          <Typography variant="h4" gutterBottom>
            {`Aircraft: ${aircraft.aircraftNumber}`}
          </Typography>

          <Button variant="contained" color="primary" onClick={() => handleOpen()}>
            Add Inspection
          </Button>

          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
            }}
          >
            <DialogTitle>{editMode ? 'Edit Inspection' : 'Add Inspection'}</DialogTitle>
            <DialogContent>
              <Select
                margin="dense"
                label="Inspection Type"
                name="inspectionId"
                value={inspectionData.inspectionId}
                onChange={handleChange}
                fullWidth
                sx={{
                  fontSize: '0.875rem',
                  mt: 1,
                  '& .MuiInputBase-input': {
                    color: theme.palette.text.primary,
                  },
                  '& .MuiInputLabel-root': {
                    color: theme.palette.text.secondary,
                  },
                }}
              >
                {inspectionTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id} sx={{ fontSize: '0.875rem' }}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                margin="dense"
                label="Due Date"
                type="date"
                name="dueDate"
                value={inspectionData.dueDate}
                onChange={handleChange}
                fullWidth
                sx={{
                  fontSize: '0.875rem',
                  mt: 2,
                  '& .MuiInputBase-input': {
                    color: theme.palette.text.primary,
                  },
                  '& .MuiInputLabel-root': {
                    color: theme.palette.text.secondary,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleSaveInspection} color="primary">
                {editMode ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Dialog>

          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Inspections
            </Typography>
            {inspections.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Inspection Type</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inspections.map((inspection, index) => (
                      <TableRow key={index}>
                        <TableCell>{inspectionTypes.find(type => type.id === inspection.inspectionId)?.name}</TableCell>
                        <TableCell>{inspection.dueDate}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleOpen(index)}
                            sx={{ marginRight: 1 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteInspection(index)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No inspections scheduled.</Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AircraftInspections;
