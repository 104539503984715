import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { Box, Card, CardContent, Typography, CardActions, Button, Alert, useTheme } from '@mui/material';

function UserInformation({ userData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const theme = useTheme(); // Access the current theme

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage('');

    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      // Assuming you have logic to update the user data
      await updateDoc(userRef, {
        // Add fields to update here, e.g., name: userData.name
      });

      setSuccessMessage('Profile updated successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3 }, display: 'flex', justifyContent: 'center' }}>
      <Card
        elevation={3}
        sx={{
          width: { xs: '100%', sm: '600px' }, // Responsive width
          mx: 'auto',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <CardContent sx={{ textAlign: 'left' }}>
          <Typography variant="h6" gutterBottom>
            User Information
          </Typography>
          <Typography variant="body1" margin="normal">
            Name: {userData.name}
          </Typography>
          <Typography variant="body1" margin="normal">
            Email: {userData.email}
          </Typography>
          <Typography variant="body1">Role: {userData.role}</Typography>
          <Typography variant="body1">Status: {userData.status}</Typography>
          <Typography variant="body1">
            Location: {userData.locationId || 'Not assigned'}
          </Typography>
          <Typography variant="body1">
            Building: {userData.buildingId || 'Not assigned'}
          </Typography>
        </CardContent>
        <CardActions sx={{ px: 2, pb: 2 }}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleSubmit}
          >
            Update Profile
          </Button>
        </CardActions>
        {error && <Alert severity="error" sx={{ mt: 2, mx: 2 }}>{error}</Alert>}
        {successMessage && (
          <Alert severity="success" sx={{ mt: 2, mx: 2 }}>
            {successMessage}
          </Alert>
        )}
      </Card>
    </Box>
  );
}

export default UserInformation;
