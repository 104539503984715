import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Select, 
  MenuItem,
  useTheme 
} from '@mui/material';
import { blue } from '@mui/material/colors';

function LocationManagement() {
  const theme = useTheme(); // Access the current theme
  const [openAddLocation, setOpenAddLocation] = useState(false);
  const [openAddBuilding, setOpenAddBuilding] = useState(false);
  const [locationData, setLocationData] = useState({ locationId: '' });
  const [buildingData, setBuildingData] = useState({ locationId: '', buildingId: '' });
  const [locationsList, setLocationsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocations = async () => {
      const querySnapshot = await getDocs(collection(db, 'locations'));
      const locationsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLocationsList(locationsArray);
    };

    fetchLocations();
  }, []);

  const handleOpenAddLocation = () => setOpenAddLocation(true);
  const handleCloseAddLocation = () => setOpenAddLocation(false);

  const handleOpenAddBuilding = () => setOpenAddBuilding(true);
  const handleCloseAddBuilding = () => setOpenAddBuilding(false);

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setLocationData({ ...locationData, [name]: value });
  };

  const handleBuildingChange = (e) => {
    const { name, value } = e.target;
    setBuildingData({ ...buildingData, [name]: value });
  };

  const handleSaveLocation = async () => {
    try {
      const newLocation = {
        locationId: locationData.locationId,
        buildingIds: [],
      };
      await addDoc(collection(db, 'locations'), newLocation);
      setLocationsList([...locationsList, newLocation]);
      handleCloseAddLocation();
    } catch (error) {
      console.error('Error adding location: ', error);
    }
  };

  const handleSaveBuilding = async () => {
    try {
      const existingLocation = locationsList.find(location => location.locationId === buildingData.locationId);
      if (existingLocation && existingLocation.id) {
        const updatedBuildingIds = [...existingLocation.buildingIds, buildingData.buildingId];
        await updateDoc(doc(db, 'locations', existingLocation.id), { buildingIds: updatedBuildingIds });
        setLocationsList(locationsList.map(location =>
          location.locationId === buildingData.locationId ? { ...location, buildingIds: updatedBuildingIds } : location
        ));
        handleCloseAddBuilding();
      } else {
        console.error('Location not found or invalid document ID.');
      }
    } catch (error) {
      console.error('Error adding building: ', error);
    }
  };

  const handleBuildingClick = (locationId, buildingId) => {
    navigate(`/shops/${locationId}/${buildingId}`);
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: 'max-content',
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ fontSize: '1.25rem', mb: 2, textAlign: 'left' }}
      >
        Location Management
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAddLocation}
        sx={{
          mr: 2,
          marginBottom: '16px',
          fontSize: '0.875rem',
          padding: '8px 16px',
        }}
      >
        Add Location
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAddBuilding}
        sx={{
          mr: 2,
          marginBottom: '16px',
          fontSize: '0.875rem',
          padding: '8px 16px',
        }}
      >
        Add Building
      </Button>

      <Dialog 
        open={openAddLocation} 
        onClose={handleCloseAddLocation}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Add New Location
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Location ID"
            name="locationId"
            value={locationData.locationId}
            onChange={handleLocationChange}
            fullWidth
            sx={{ 
              fontSize: '0.875rem', 
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddLocation} color="secondary" sx={{ fontSize: '0.875rem' }}>
            Cancel
          </Button>
          <Button onClick={handleSaveLocation} color="primary" sx={{ fontSize: '0.875rem' }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={openAddBuilding} 
        onClose={handleCloseAddBuilding}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Add New Building
        </DialogTitle>
        <DialogContent>
          <Select
            margin="dense"
            label="Location ID"
            name="locationId"
            value={buildingData.locationId}
            onChange={handleBuildingChange}
            fullWidth
            sx={{ 
              fontSize: '0.875rem', 
              mt: 1,
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
            }}
          >
            {locationsList.map(location => (
              <MenuItem 
                key={location.id} 
                value={location.locationId} 
                sx={{ fontSize: '0.875rem' }}
              >
                {location.locationId}
              </MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            label="Building ID"
            name="buildingId"
            value={buildingData.buildingId}
            onChange={handleBuildingChange}
            fullWidth
            sx={{ 
              fontSize: '0.875rem', 
              mt: 1,
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddBuilding} color="secondary" sx={{ fontSize: '0.875rem' }}>
            Cancel
          </Button>
          <Button onClick={handleSaveBuilding} color="primary" sx={{ fontSize: '0.875rem' }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer 
        component={Paper} 
        sx={{ mt: 4, backgroundColor: theme.palette.background.paper }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: '8px',
                  fontSize: '0.875rem',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                }}
              >
                Location ID
              </TableCell>
              <TableCell
                sx={{
                  padding: '8px',
                  fontSize: '0.875rem',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                }}
              >
                Buildings
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationsList.map((location) => (
              <TableRow key={location.id}>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  {location.locationId}
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  {(location.buildingIds || []).map((buildingId) => (
                    <span
                      key={buildingId}
                      onClick={() => handleBuildingClick(location.locationId, buildingId)}
                      style={{
                        color: blue[500],
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        marginRight: '8px',
                      }}
                    >
                      {buildingId}
                    </span>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default LocationManagement;
