import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

// Custom hook for managing user authentication and authorization
export const useAuth = (allowedRoles = [], requiredStatus = 'approved') => {
  const [user, loading] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setUserData({ ...data, uid: user.uid }); // Include uid in userData
            setAuthorized(
              data.status === requiredStatus &&
              (allowedRoles.length === 0 || allowedRoles.includes(data.role))
            );
          } else {
            setUserData(null);
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
          setUserData(null);
        }
      }
      setUserLoading(false);
    };
    fetchUserData();
  }, [user, allowedRoles, requiredStatus]);

  return { user, userData, loading: loading || userLoading, authorized };
};

// Higher-order component for protecting routes
export const withAuth = (WrappedComponent, allowedRoles = [], requiredStatus = 'approved') => {
  return function WithAuth(props) {
    const { user, userData, loading, authorized } = useAuth(allowedRoles, requiredStatus);

    if (loading) return <div>Loading...</div>;
    if (!user || !authorized) return <Navigate to="/" />;

    return <WrappedComponent {...props} userData={userData} />;
  };
};
