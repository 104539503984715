// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAs5dhaCUXr8DRTWAgcZlVSaSNbkvqv4dE",
  authDomain: "oqlapp.firebaseapp.com",
  projectId: "oqlapp",
  storageBucket: "oqlapp.appspot.com",
  messagingSenderId: "344201100228",
  appId: "1:344201100228:web:4f19b8f7aa10f15a63c291",
  measurementId: "G-2RFVGKMC9S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
export const auth = getAuth(app);