import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  List, 
  ListItem, 
  ListItemText,
  useTheme 
} from '@mui/material';
import { db } from '../firebase';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';

function Shops() {
  const { locationId, buildingId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme(); // Access the current theme
  const [open, setOpen] = useState(false);
  const [shopName, setShopName] = useState('');
  const [shops, setShops] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchShops = useCallback(async () => {
    try {
      const shopRef = collection(db, `locations/${locationId}/buildings/${buildingId}/shops`);
      const shopSnapshot = await getDocs(shopRef);
      const shopList = shopSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setShops(shopList);
    } catch (error) {
      console.error('Error fetching shops: ', error);
    }
  }, [locationId, buildingId]);

  const handleSave = async () => {
    if (shopName.trim() === '') {
      alert('Shop Name cannot be empty');
      return;
    }

    try {
      const shopRef = doc(db, `locations/${locationId}/buildings/${buildingId}/shops`, shopName);
      await setDoc(shopRef, { shopName });
      setShopName('');
      handleClose();
      fetchShops(); // Refresh the list of shops
    } catch (error) {
      console.error('Error adding shop: ', error);
      alert('Failed to add shop. Please try again.');
    }
  };

  useEffect(() => {
    fetchShops();
  }, [fetchShops]);

  const handleShopClick = (shopId) => {
    navigate(`/shops/${locationId}/${buildingId}/${shopId}/employees`);
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme background color
        color: theme.palette.text.primary, // Theme text color
        minHeight: 'max-content', // Ensure full height for the page
      }}
    >
      <Typography variant="h4" gutterBottom>
        Shops in Building {buildingId}
      </Typography>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Location: {locationId}
      </Typography>

      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Shop
      </Button>

      <Dialog 
        open={open} 
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper, // Dialog background color
            color: theme.palette.text.primary, // Dialog text color
          } 
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>Add New Shop</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Shop Name"
            type="text"
            fullWidth
            variant="standard"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}

          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h6" sx={{ mt: 4 }}>
        List of Shops:
      </Typography>
      <List sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 1 }}>
        {shops.map(shop => (
          <ListItem
            button
            key={shop.id}
            onClick={() => handleShopClick(shop.id)}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              }
            }}
          >
            <ListItemText 
              primary={shop.shopName} 
              sx={{ color: theme.palette.text.primary }} // Text color for list items
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default Shops;
