import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/authUtils';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { 
  Box, 
  Button, 
  Modal, 
  Paper, 
  TextField, 
  Typography, 
  MenuItem, 
  Select, 
  IconButton, 
  Grid,
  useTheme 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ProgressBar from '../components/ProgressBar';

function ProjectsPage() {
  const { userData, loading, user } = useAuth();
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [newProject, setNewProject] = useState({
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    personnelRequired: '',
    budgetRequired: '',
    documents: null,
    status: 'Upcoming',
  });
  const navigate = useNavigate();
  const theme = useTheme(); // Access the current theme

  const fetchProjects = useCallback(async () => {
    if (userData && userData.uid) {
      let q;
      if (userData.role === 'Manager') {
        q = query(collection(db, 'projects'), where('managerIds', 'array-contains', userData.uid));
      } else if (userData.role === 'Admin') {
        q = query(collection(db, 'projects')); // Admins can view all projects
      }
      
      try {
        const querySnapshot = await getDocs(q);
        const fetchedProjects = await Promise.all(querySnapshot.docs.map(async doc => {
          const project = { id: doc.id, ...doc.data() };
  
          // Fetch tasks for this project
          const tasksQuery = query(collection(db, `projects/${project.id}/tasks`));
          const tasksSnapshot = await getDocs(tasksQuery);
          project.tasks = tasksSnapshot.docs.map(taskDoc => ({
            id: taskDoc.id,
            ...taskDoc.data(),
          }));
  
          return project;
        }));
        setProjects(fetchedProjects);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    } else {
      console.log('User data or UID is not available.');
    }
  }, [userData]);
  

  useEffect(() => {
    if (user && userData && userData.uid && !loading) {
      fetchProjects();
    } else {
      console.log('Waiting for user data to load or user not logged in');
    }
  }, [user, userData, loading, fetchProjects]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
  };

  const handleFileChange = (e) => {
    setNewProject({ ...newProject, documents: e.target.files[0] });
  };

  const handleAddProject = async () => {
    if (!newProject.title || !newProject.description) {
      alert('Please fill all fields');
      return;
    }

    if (!userData || !userData.uid) {
      console.error('UserData is not available or UID is undefined.');
      alert('Unable to identify user. Please try logging in again.');
      return;
    }

    try {
      if (isEditing) {
        // Update the project in Firestore
        const projectDocRef = doc(db, 'projects', currentProjectId);
        await updateDoc(projectDocRef, newProject);
        setProjects(projects.map(project => project.id === currentProjectId ? { ...newProject, id: currentProjectId } : project));
        setIsEditing(false);
      } else {
        // Add a new project to Firestore
        const docRef = await addDoc(collection(db, 'projects'), {
          ...newProject,
          managerIds: [userData.uid],
          createdAt: new Date(),
        });
        setProjects([...projects, { ...newProject, id: docRef.id }]);
      }

      setShowModal(false);
      setNewProject({
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        personnelRequired: '',
        budgetRequired: '',
        documents: null,
        status: 'Upcoming',
      });
      setCurrentProjectId(null);
    } catch (error) {
      console.error('Error adding or updating project:', error);
      alert('Failed to add or update project. Please try again.');
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteDoc(doc(db, 'projects', projectId));
      await fetchProjects(); // Refresh the projects after deletion
    } catch (error) {
      console.error('Error deleting project:', error);
      alert('Failed to delete project. Please try again.');
    }
  };

  const handleEditProject = (project) => {
    setNewProject(project);
    setCurrentProjectId(project.id);
    setIsEditing(true);
    setShowModal(true);
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme background color
        color: theme.palette.text.primary, // Theme text color
        minHeight: '100vh', // Ensure full height for the page
      }}
    >
      <Typography variant="h4" gutterBottom>Projects Dashboard</Typography>
      <Button variant="contained" color="primary" onClick={() => {
        setIsEditing(false);
        setShowModal(true);
      }}>Add New Project</Button>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          component={Paper}
          sx={{
            p: { xs: 2, md: 4 }, // Responsive padding
            maxWidth: 500,
            mx: 'auto',
            mt: '10vh', // Adjust to reduce vertical space
            maxHeight: '80vh', // Limit height to make content scrollable
            overflowY: 'auto', // Enable vertical scroll if content exceeds maxHeight
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper, // Theme paper background color
            color: theme.palette.text.primary, // Theme text color
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5" gutterBottom>
            {isEditing ? 'Edit Project' : 'Add New Project'}
          </Typography>
          <TextField
            fullWidth
            name="title"
            value={newProject.title}
            onChange={handleInputChange}
            label="Project Title"
            margin="normal"
            required
          />
          <TextField
            fullWidth
            name="description"
            value={newProject.description}
            onChange={handleInputChange}
            label="Project Description"
            multiline
            rows={4}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            type="date"
            name="startDate"
            value={newProject.startDate}
            onChange={handleInputChange}
            label="Start Date"
            margin="normal"
            required
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            type="date"
            name="endDate"
            value={newProject.endDate}
            onChange={handleInputChange}
            label="End Date"
            margin="normal"
            required
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            type="number"
            name="personnelRequired"
            value={newProject.personnelRequired}
            onChange={handleInputChange}
            label="Personnel Required"
            margin="normal"
            required
          />
          <TextField
            fullWidth
            type="number"
            name="budgetRequired"
            value={newProject.budgetRequired}
            onChange={handleInputChange}
            label="Budget Required"
            margin="normal"
            required
          />
          <Select
            fullWidth
            name="status"
            value={newProject.status}
            onChange={handleInputChange}
            required
            sx={{ mt: 2}} // Add spacing to align with other inputs
          >
            <MenuItem value="Upcoming">Upcoming</MenuItem>
            <MenuItem value="In-progress">In-progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="On-Hold">On-Hold</MenuItem>
          </Select>
          <TextField
            fullWidth
            type="file"
            name="documents"
            onChange={handleFileChange}
            margin="normal"
            sx={{ mt: 2 }} // Align with other inputs
          />

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" onClick={handleAddProject}>
              {isEditing ? 'Save Changes' : 'Save Project'}
            </Button>
            <Button variant="outlined" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          {projects.length > 0 ? projects.map(project => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Paper
                sx={{
                  p: 2,
                  position: 'relative',
                  minHeight: '150px',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.background.paper, // Theme paper background color
                  color: theme.palette.text.primary, // Theme text color
                }}
              >
                <Box onClick={() => navigate(`/projects/${project.id}`)} sx={{ cursor: 'pointer', flexGrow: 1 }}>
                  <Typography variant="subtitle1">{project.title}</Typography>
                  <Typography variant="body2">{project.description}</Typography>
                  <Typography variant="caption">Status: {project.status}</Typography>
                </Box>
                <ProgressBar tasks={project.tasks || []} /> {/* Add the ProgressBar component here */}
                <IconButton
                  color="primary"
                  sx={{ position: 'absolute', top: 8, right: 40 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditProject(project);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteProject(project.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Paper>
            </Grid>
          )) : (
            <Typography variant="body1">You don't have any projects yet.</Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default ProjectsPage;
