import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  useTheme 
} from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Employees() {
  const { locationId, buildingId, shopId } = useParams();
  const theme = useTheme(); // Access the current theme
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    lastName: '',
    shift: '',
    quals: '',
    remarks: '',
    status: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setCurrentEmployeeId(null);
    setEmployeeData({
      firstName: '',
      lastName: '',
      shift: '',
      quals: '',
      remarks: '',
      status: ''
    });
  };

  const fetchEmployees = useCallback(async () => {
    try {
      const employeeRef = collection(db, `locations/${locationId}/buildings/${buildingId}/shops/${shopId}/employees`);
      const employeeSnapshot = await getDocs(employeeRef);
      const employeeList = employeeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeeList);
    } catch (error) {
      setErrorDialogMessage('Error fetching employees. Please try again.');
      setErrorDialogOpen(true);
    }
  }, [locationId, buildingId, shopId]);

  const handleSave = async () => {
    if (employeeData.firstName.trim() === '' || employeeData.lastName.trim() === '') {
      setErrorDialogMessage('First Name and Last Name cannot be empty');
      setErrorDialogOpen(true);
      return;
    }

    try {
      if (isEditing && currentEmployeeId) {
        const employeeRef = doc(db, `locations/${locationId}/buildings/${buildingId}/shops/${shopId}/employees`, currentEmployeeId);
        await updateDoc(employeeRef, employeeData);
      } else {
        const employeeRef = collection(db, `locations/${locationId}/buildings/${buildingId}/shops/${shopId}/employees`);
        await addDoc(employeeRef, employeeData);
      }
      handleClose();
      fetchEmployees();
    } catch (error) {
      setErrorDialogMessage('Failed to save employee. Please try again.');
      setErrorDialogOpen(true);
    }
  };

  const handleEdit = (employee) => {
    setEmployeeData(employee);
    setIsEditing(true);
    setCurrentEmployeeId(employee.id);
    setOpen(true);
  };

  const handleDelete = async () => {
    if (employeeToDelete) {
      try {
        const employeeRef = doc(db, `locations/${locationId}/buildings/${buildingId}/shops/${shopId}/employees`, employeeToDelete);
        await deleteDoc(employeeRef);
        fetchEmployees(); // Refresh the list of employees
      } catch (error) {
        setErrorDialogMessage('Failed to delete employee. Please try again.');
        setErrorDialogOpen(true);
      } finally {
        setDeleteDialogOpen(false);
        setEmployeeToDelete(null);
      }
    }
  };

  const confirmDelete = (employeeId) => {
    setEmployeeToDelete(employeeId);
    setDeleteDialogOpen(true);
  };

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme background color
        color: theme.palette.text.primary, // Theme text color
        minHeight: '100vh', // Ensure full height of the page
      }}
    >
      <Typography variant="h4" gutterBottom>
        Employees that belong to {locationId}, {buildingId}, {shopId}
      </Typography>

      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Employee to Shop
      </Button>

      <Dialog open={open} onClose={handleClose} PaperProps={{
        sx: {backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }
      }}>
        <DialogTitle sx={{ color: theme.palette.text.primary }}>Add New Employee</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            value={employeeData.firstName}
            onChange={(e) => setEmployeeData({ ...employeeData, firstName: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            value={employeeData.lastName}
            onChange={(e) => setEmployeeData({ ...employeeData, lastName: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Shift"
            type="text"
            fullWidth
            variant="standard"
            value={employeeData.shift}
            onChange={(e) => setEmployeeData({ ...employeeData, shift: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Qualifications"
            type="text"
            fullWidth
            variant="standard"
            value={employeeData.quals}
            onChange={(e) => setEmployeeData({ ...employeeData, quals: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Remarks"
            type="text"
            fullWidth
            variant="standard"
            value={employeeData.remarks}
            onChange={(e) => setEmployeeData({ ...employeeData, remarks: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Status"
            type="text"
            fullWidth
            variant="standard"
            value={employeeData.status}
            onChange={(e) => setEmployeeData({ ...employeeData, status: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h6" sx={{ mt: 4 }}>
        List of Employees:
      </Typography>

      <TableContainer component={Paper} sx={{ mt: 4, backgroundColor: theme.palette.background.paper }}>
        <Table>
          <TableHead>
            <TableRow>
              {['Employee Name', 'Shift', 'Qualifications', 'Remarks', 'Status', 'Actions'].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    padding: '8px',
                    fontSize: '0.875rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundColor: theme.palette.background.paper, // Theme background color
                    color: theme.palette.text.primary, // Theme text color
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  {employee.firstName} {employee.lastName}
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  {employee.shift}
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  {employee.quals}
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  {employee.remarks}
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  {employee.status}
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  <IconButton color="primary" onClick={() => handleEdit(employee)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => confirmDelete(employee.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Error Dialog */}
      <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorDialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialogOpen(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this employee? Once deleted, their information cannot be retrieved.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">No</Button>
          <Button onClick={handleDelete} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Employees;
