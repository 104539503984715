import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import Aircraft from '../components/Aircraft';

function AircraftPage() {
  const theme = useTheme(); // Access the current theme

  return (
    <Box
      sx={{
        textAlign: 'center',
        mt: 4,
        p: { xs: 2, md: 4 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme background color
        color: theme.palette.text.primary, // Theme text color
        minHeight: '100vh', // Ensure full height for the page
      }}
    >
      <Typography variant="h4" gutterBottom>
        Aircraft
      </Typography>
      <Aircraft />
    </Box>
  );
}

export default AircraftPage;
