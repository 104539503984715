import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, getDocs, updateDoc, doc } from 'firebase/firestore';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Button,
  useTheme,
} from '@mui/material';

const roles = ['Building Admin','Mechanic', 'Supervisor','Quality Assurance', 'Manager', 'Corporate', 'Admin'];

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchUsers = async () => {
      const q = query(collection(db, 'users'));
      const querySnapshot = await getDocs(q);
      setUsers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchLocations = async () => {
      const locationsSnapshot = await getDocs(collection(db, 'locations'));
      const locationsData = locationsSnapshot.docs.map(doc => doc.data());
      setLocations(locationsData);
    };

    fetchUsers();
    fetchLocations();
  }, []);

  const handleUpdateUser = async (userId, updates) => {
    await updateDoc(doc(db, 'users', userId), updates);
    setUsers(users.map(user => 
      user.id === userId ? { ...user, ...updates } : user
    ));
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: 'max-content',
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ fontSize: '1.25rem', mb: 2, textAlign: 'left' }}
      >
        User Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          mb: 2,
          fontSize: '0.875rem',
          padding: '8px 16px',
        }}
      >
        Save Changes
      </Button>
      <TableContainer component={Paper} sx={{ mt: 4, backgroundColor: theme.palette.background.paper }}>
        <Table>
          <TableHead>
            <TableRow>
              {['Name', 'Email', 'Role', 'Status', 'Location', 'Building', 'Actions'].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    padding: '8px',
                    fontSize: '0.875rem',
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>{user.name}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>{user.email}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  <Select
                    value={user.role}
                    onChange={(e) => handleUpdateUser(user.id, { role: e.target.value })}
                    sx={{ fontSize: '0.875rem', minWidth: '100%', height: '32px' }}
                  >
                    {roles.map(role => (
                      <MenuItem key={role} value={role} sx={{ fontSize: '0.875rem' }}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  <Select
                    value={user.status}
                    onChange={(e) => handleUpdateUser(user.id, { status: e.target.value })}
                    sx={{ fontSize: '0.875rem', minWidth: '100%', height: '32px' }}
                  >
                    <MenuItem value="pending" sx={{ fontSize: '0.875rem' }}>Pending</MenuItem>
                    <MenuItem value="approved" sx={{ fontSize: '0.875rem' }}>Approved</MenuItem>
                    <MenuItem value="denied" sx={{ fontSize: '0.875rem' }}>Denied</MenuItem>
                  </Select>
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  <Select
                    value={user.locationId || ''}
                    onChange={(e) => handleUpdateUser(user.id, { locationId: e.target.value })}
                    sx={{ fontSize: '0.875rem', minWidth: '100%', height: '32px' }}
                  >
                    {locations.map(location => (
                      <MenuItem key={location.locationId} value={location.locationId} sx={{ fontSize: '0.875rem' }}>
                        {location.locationId}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell sx={{ padding: '8px', fontSize: '0.875rem' }}>
                  <Select
                    value={user.buildingId || ''}
                    onChange={(e) => handleUpdateUser(user.id, { buildingId: e.target.value })}
                    sx={{ fontSize: '0.875rem', minWidth: '100%', height: '32px' }}
                  >
                    {(locations.find(loc => loc.locationId === user.locationId)?.buildingIds || []).map(buildingId => (
                      <MenuItem key={buildingId} value={buildingId} sx={{ fontSize: '0.875rem' }}>
                        {buildingId}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell sx={{ padding: '8px', display: 'flex', gap: 0.5, justifyContent: 'center' }}>
                  {user.status === 'pending' && (
                    <>
                      <Button
                        onClick={() => handleUpdateUser(user.id, { status: 'approved' })}
                        variant="contained"
                        color="primary"
                        sx={{ fontSize: '0.75rem', padding: '4px 8px' }}
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={() => handleUpdateUser(user.id, { status: 'denied' })}
                        variant="outlined"
                        color="secondary"
                        sx={{ fontSize: '0.75rem', padding: '4px 8px' }}
                      >
                        Deny
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default UserManagement;
