import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function TdManagement() {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tdData, setTdData] = useState({
    id: '',  // Include 'id' in state for tracking
    name: '',
    duration: 1,
  });
  const [tdList, setTdList] = useState([]);
  const [userRole, setUserRole] = useState(null); // Assume a mechanism to fetch user role

  useEffect(() => {
    const fetchUserRole = async () => {
      // Fetch the user role from the users collection or through another mechanism
      const role = "admin"; // Replace with actual logic
      setUserRole(role);
    };

    const fetchTds = async () => {
      const querySnapshot = await getDocs(collection(db, 'technicalDirectives'));
      const tdsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTdList(tdsArray);
    };

    fetchUserRole();
    fetchTds();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setTdData({ id: '', name: '', duration: 1 });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTdData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (userRole === 'admin') {
        if (editMode) {
          const tdRef = doc(db, 'technicalDirectives', tdData.id);
          await updateDoc(tdRef, {
            name: tdData.name,
            duration: tdData.duration,
          });
          setTdList(tdList.map(td => 
            td.id === tdData.id ? tdData : td
          ));
        } else {
          const docRef = await addDoc(collection(db, 'technicalDirectives'), {
            name: tdData.name,
            duration: tdData.duration,
          });
          setTdList([...tdList, { id: docRef.id, ...tdData }]);
        }
        handleClose();
      } else {
        console.error('Permission denied: Only admins can perform this action.');
      }
    } catch (error) {
      console.error('Error adding/editing TD: ', error);
    }
  };

  const handleEdit = (td) => {
    setTdData(td);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      if (userRole === 'admin') {
        await deleteDoc(doc(db, 'technicalDirectives', id));
        setTdList(tdList.filter(td => td.id !== id));
      } else {
        console.error('Permission denied: Only admins can perform this action.');
      }
    } catch (error) {
      console.error('Error deleting TD: ', error);
    }
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        minHeight: 'max-content',
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Technical Directives Management
      </Typography>
      {userRole === 'admin' && (
        <Button variant="contained" onClick={handleOpen} sx={{ mb: 2 }}>
          Add Technical Directive
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editMode ? 'Edit Technical Directive' : 'Add New Technical Directive'}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="TD Name"
            name="name"
            value={tdData.name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Duration (days)"
            name="duration"
            type="number"
            value={tdData.duration}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {editMode ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {['Name', 'Duration', 'Actions'].map((header) => (
                <TableCell key={header}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tdList.map((td) => (
              <TableRow key={td.id}>
                <TableCell>{td.name}</TableCell>
                <TableCell>{td.duration} days</TableCell>
                {userRole === 'admin' && (
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => handleEdit(td)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDelete(td.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default TdManagement;
