import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Typography, useTheme } from '@mui/material';

function ProgressBar({ tasks }) {
  const [progress, setProgress] = useState(0);
  const theme = useTheme(); // Access the current theme

  useEffect(() => {
    const calculateProgress = (tasks) => {
      if (!tasks || tasks.length === 0) return 0;

      let totalTasks = 0;
      let completedTasks = 0;

      const countTasks = (tasks) => {
        tasks.forEach((task) => {
          totalTasks += 1;
          if (task.status === 'Completed') {
            completedTasks += 1;
          }
          if (Array.isArray(task.children) && task.children.length > 0) {
            countTasks(task.children);
          }
        });
      };

      countTasks(tasks);

      return totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
    };

    setProgress(calculateProgress(tasks));
  }, [tasks]);

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2,
        backgroundColor: theme.palette.background.paper, // Theme-based background color
        color: theme.palette.text.primary, // Theme-based text color
        p: 2, // Padding for spacing around the progress bar
        borderRadius: 1, // Slight rounding of the corners
      }}
    >
      <Typography variant="body1" gutterBottom>{`Progress: ${progress}%`}</Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 10, // Increased height for better visibility
          borderRadius: 5, // Rounded corners for the progress bar
          backgroundColor: theme.palette.grey[300], // Lighter background for the progress track
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.primary.main, // Use the primary color for the progress bar
          },
        }}
      />
    </Box>
  );
}

export default ProgressBar;
