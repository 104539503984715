import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, query, getDocs, updateDoc } from 'firebase/firestore';
import { Box, Typography, Paper, Button, Modal, TextField, useTheme } from '@mui/material';
import TaskTable from './TaskTable';
import ProgressBar from './ProgressBar';

function ProjectDetail() {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editProject, setEditProject] = useState({
    title: '',
    description: '',
    status: '',
    startDate: '',
    endDate: '',
    personnelRequired: '',
    budgetRequired: '',
  });

  const theme = useTheme(); // Access the current theme

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const docRef = doc(db, 'projects', projectId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProject(docSnap.data());
          setEditProject(docSnap.data()); // Pre-fill the edit form with project data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };

    const fetchTasks = async () => {
      try {
        const q = query(collection(db, `projects/${projectId}/tasks`));
        const querySnapshot = await getDocs(q);
        const tasks = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        const buildTree = (tasks, parentId = null) => {
          return tasks
            .filter(task => task.parentId === parentId)
            .map(task => ({
              ...task,
              children: buildTree(tasks, task.id),
            }));
        };

        const taskTree = buildTree(tasks);
        setTasks(taskTree);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchProject();
    fetchTasks();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditProject(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, editProject);
      setProject(editProject); // Update the project state with the new data
      setEditModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  if (!project) {
    return <Typography variant="body1" sx={{ mt: 4 }}>Loading...</Typography>;
  }

  return (
    <Box
      component={Paper}
      sx={{
        p: { xs: 2, md: 4 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme-based background color
        color: theme.palette.text.primary, // Theme-based text color
        borderRadius: 2, // Slight rounding of the corners for a modern look
      }}
    >
      <Typography variant="h4" gutterBottom>{project.title}</Typography>
      <Typography variant="body1" paragraph>{project.description}</Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>Status: {project.status}</Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>Start Date: {project.startDate}</Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>End Date: {project.endDate}</Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>Personnel Required: {project.personnelRequired}</Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>Budget Required: {project.budgetRequired}</Typography>
      
      {/* Edit Button */}
      <Button variant="contained" color="primary" onClick={() => setEditModalOpen(true)} sx={{ mt: 2 }}>
        Edit Project
      </Button>

      <ProgressBar tasks={tasks} />
      <TaskTable projectId={projectId} />

      {/* Edit Modal */}
      <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          component={Paper}
          sx={{
            p: 4,
            maxWidth: 500,
            mx: 'auto',
            mt: '20vh',
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper, // Theme paper background
          }}
        >
          <Typography variant="h6" gutterBottom>Edit Project</Typography>
          <TextField
            fullWidth
            name="title"
            label="Project Title"
            value={editProject.title}
            onChange={handleInputChange}
            margin="normal"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="description"
            label="Project Description"
            value={editProject.description}
            onChange={handleInputChange}
            margin="normal"
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="status"
            label="Status"
            value={editProject.status}
            onChange={handleInputChange}
            margin="normal"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="startDate"
            label="Start Date"
            type="date"
            value={editProject.startDate}
            onChange={handleInputChange}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="endDate"
            label="End Date"
            type="date"
            value={editProject.endDate}
            onChange={handleInputChange}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="personnelRequired"
            label="Personnel Required"
            value={editProject.personnelRequired}
            onChange={handleInputChange}
            margin="normal"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="budgetRequired"
            label="Budget Required"
            value={editProject.budgetRequired}
            onChange={handleInputChange}
            margin="normal"
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSaveChanges} sx={{ mt: 2 }}>
            Save Changes
          </Button>
          <Button variant="outlined" onClick={() => setEditModalOpen(false)} sx={{ mt: 2, ml: 2 }}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default ProjectDetail;
