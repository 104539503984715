import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../firebase';
import { addDoc, updateDoc, deleteDoc, collection, query, getDocs, doc, getDoc, orderBy, where } from 'firebase/firestore';
import DownloadProjectTaskList from './DownloadProjectTaskList'; 
import {
  Box,
  Button,
  Typography,
  Modal,
  Paper,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Grid,
  InputBase,
  useTheme,
} from '@mui/material';
import { Add, Edit, Delete, ExpandMore, ExpandLess } from '@mui/icons-material';

const statusColors = {
  Pending: '#FFF3CD',
  'In-progress': '#CCE5FF',
  Completed: '#D4EDDA',
  'On-Hold': '#F8D7DA',
};

function TaskTable({ projectId }) {
  const theme = useTheme(); // Use theme for consistent styling
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showSubtaskModal, setShowSubtaskModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [newTask, setNewTask] = useState({ name: '', details: '', status: 'Pending' });
  const [expandedTasks, setExpandedTasks] = useState({});
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [project, setProject] = useState(null);

  const fetchTasks = useCallback(async () => {
    try {
      const q = query(
        collection(db, `projects/${projectId}/tasks`),
        orderBy('createdAt')
      );
      const querySnapshot = await getDocs(q);
      const tasks = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const buildTreeAndExpand = (tasks, parentId = null) => {
        return tasks
          .filter(task => task.parentId === parentId)
          .map(task => {
            setExpandedTasks(prevState => ({ ...prevState, [task.id]: true }));
            return {
              ...task,
              children: buildTreeAndExpand(tasks, task.id),
            };
          });
      };

      const taskTree = buildTreeAndExpand(tasks);
      setTasks(taskTree);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  }, [projectId]);

  const fetchProject = useCallback(async () => {
    try {
      const projectRef = doc(db, 'projects', projectId);
      const projectSnap = await getDoc(projectRef);
      if (projectSnap.exists()) {
        setProject(projectSnap.data());
      } else {
        console.error('No such project!');
      }
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  }, [projectId]);

  useEffect(() => {
    fetchTasks();
    fetchProject();
  }, [fetchTasks, fetchProject]);

  const handleInputChange = (e, setState) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAddOrUpdateTask = async (parentId = null) => {
    if (!newTask.name || !newTask.details) {
      alert('Please fill all fields');
      return;
    }

    try {
      const taskData = {
        ...newTask,
        createdAt: new Date(),
        parentId,
      };

      if (editingTaskId) {
        const taskRef = doc(db, `projects/${projectId}/tasks`, editingTaskId);
        await updateDoc(taskRef, taskData);

        setTasks(prevTasks => {
          const updateTaskInTree = (tasks) => {
            return tasks.map(task => {
              if (task.id === editingTaskId) {
                return { ...task, ...taskData };
              }
              if (task.children) {
                return { ...task, children: updateTaskInTree(task.children) };
              }
              return task;
            });
          };
          return updateTaskInTree(prevTasks);
        });
      } else {
        const docRef = await addDoc(collection(db, `projects/${projectId}/tasks`), taskData);
        taskData.id = docRef.id;

        setTasks(prevTasks => {
          const addTaskToTree = (tasks) => {
            if (parentId === null) {
              return [...tasks, taskData];
            }
            return tasks.map(task => {
              if (task.id === parentId) {
                return {
                  ...task,
                  children: [...(task.children || []), taskData],
                };
              }
              if (task.children) {
                return { ...task, children: addTaskToTree(task.children) };
              }
              return task;
            });
          };
          return addTaskToTree(prevTasks);
        });
      }

      setShowTaskModal(false);
      setShowSubtaskModal(false);
      setNewTask({ name: '', details: '', status: 'Pending' });
      setEditingTaskId(null);
    } catch (error) {
      console.error('Error adding/updating task:', error);
      alert('Failed to add/update task. Please try again.');
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      const deleteSubtasks = async (taskId) => {
        const subtaskQuery = query(collection(db, `projects/${projectId}/tasks`), where('parentId', '==', taskId));
        const subtaskSnapshot = await getDocs(subtaskQuery);

        const deletePromises = subtaskSnapshot.docs.map(async (subtaskDoc) => {
          await deleteSubtasks(subtaskDoc.id);
          await deleteDoc(doc(db, `projects/${projectId}/tasks`, subtaskDoc.id));
        });

        await Promise.all(deletePromises);
      };

      await deleteSubtasks(taskId);
      await deleteDoc(doc(db, `projects/${projectId}/tasks`, taskId));

      setTasks(prevTasks => {
        const deleteTaskFromTree = (tasks) => {
          return tasks
            .filter(task => task.id !== taskId)
            .map(task => {
              if (task.children) {
                return { ...task, children: deleteTaskFromTree(task.children) };
              }
              return task;
            });
        };
        return deleteTaskFromTree(prevTasks);
      });
    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Failed to delete task. Please try again.');
    }
  };

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      const taskRef = doc(db, `projects/${projectId}/tasks`, taskId);
      await updateDoc(taskRef, { status: newStatus });

      setTasks(prevTasks => {
        const updateStatusInTree = (tasks) => {
          return tasks.map(task => {
            if (task.id === taskId) {
              return { ...task, status: newStatus };
            }
            if (task.children) {
              return { ...task, children: updateStatusInTree(task.children) };
            }
            return task;
          });
        };
        return updateStatusInTree(prevTasks);
      });
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    }
  };

  const toggleTask = (taskId) => {
    setExpandedTasks(prevState => ({
      ...prevState,
      [taskId]: !prevState[taskId],
    }));
  };

  const handleEditTask = (taskId) => {
    const findTaskById = (tasks) => {
      for (let task of tasks) {
        if (task.id === taskId) return task;
        if (task.children) {
          const found = findTaskById(task.children);
          if (found) return found;
        }
      }
      return null;
    };

    const taskToEdit = findTaskById(tasks);
    setNewTask({ ...taskToEdit });
    setEditingTaskId(taskId);
    setShowTaskModal(true);
  };

  const renderTasks = (tasks, level = 0) => {
    return tasks.map((task) => (
      <Grid
        container
        key={task.id}
        sx={{ alignItems: "center", mb: level === 0 ? 2 : 0 }}
      >
        <Grid item xs={12} sm={4} md={2.5} sx={{ display: "flex", alignItems: "center", pl: `${level * 20}px` }}>
          <IconButton onClick={() => toggleTask(task.id)} size="small">
            {expandedTasks[task.id] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography variant="body1">{task.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3.5}>
          <Typography variant="body2">{task.details}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={2.5} sx={{ display: "flex", justifyContent: "flex-start", mt: { xs: 1, sm: 0 } }}>
          <Select
            value={task.status}
            onChange={(e) => handleStatusChange(task.id, e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              backgroundColor: statusColors[task.status] || "#FFF",
              color: 'black',
              maxWidth: "200px",
            }}
            input={<InputBase sx={{ pl: 1 }} />}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="In-progress">In-progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="On-Hold">On-Hold</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={8} md={3.5} sx={{ display: "flex", justifyContent: "flex-start", gap: "4px", mt: { xs: 1, sm: 0 } }}>
          <IconButton onClick={() => { setCurrentTask(task); setShowSubtaskModal(true); }} title="Add Subtask" size="small">
            <Add />
          </IconButton>
          <IconButton onClick={() => handleEditTask(task.id)} title="Edit Task" size="small">
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDeleteTask(task.id)} title="Delete Task" size="small">
            <Delete />
          </IconButton>
        </Grid>
        {expandedTasks[task.id] && task.children && renderTasks(task.children, level + 1)}
      </Grid>
    ));
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, backgroundColor: theme.palette.background.default }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>Tasks</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { setCurrentTask(null); setShowTaskModal(true); }}
            sx={{ mr: 2, fontSize: '0.875rem', padding: '6px 12px', minWidth: '120px' }}
          >
            Add Task
          </Button>
          <DownloadProjectTaskList tasks={tasks} project={project} />
        </Box>
      </Box>
      <Box>
        <Grid container spacing={2} sx={{ mb: 2}}>
          <Grid item xs={12} sm={4} md={2.5}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Task/Subtask</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={3.5}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Details</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.5}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold'}}>Status</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={3.5}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Actions</Typography>
          </Grid>
        </Grid>
        {tasks.length > 0 ? renderTasks(tasks) : <Typography variant="body2">No tasks yet.</Typography>}
      </Box>

      {/* Task Modal */}
      <Modal open={showTaskModal} onClose={() => setShowTaskModal(false)}>
        <Paper sx={{ p: 3, width: 400, mx: 'auto', mt: '20vh', backgroundColor: theme.palette.background.paper,  color: theme.palette.text.primary  }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {editingTaskId ? 'Edit Task' : 'Add New Task'}
          </Typography>
          <TextField
            fullWidth
            name="name"
            label="Task Name"
            value={newTask.name}
            onChange={(e) => handleInputChange(e, setNewTask)}
            margin="dense"
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="details"
            label="Task Details"
            value={newTask.details}
            onChange={(e) => handleInputChange(e, setNewTask)}
            margin="dense"
            multiline
            rows={4}
            required
            sx={{ mb: 2 }}
          />
          <Select
            fullWidth
            name="status"
            value={newTask.status}
            onChange={(e) => handleInputChange(e, setNewTask)}
            margin="dense"
            required
            sx={{ mb: 2,  color: theme.palette.text.primary  }}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="In-progress">In-progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="On-Hold">On-Hold</MenuItem>
          </Select>
          <Button onClick={() => handleAddOrUpdateTask(currentTask ? currentTask.id : null)} variant="contained" color="primary" sx={{ mt: 2, fontSize: '0.875rem' }}>
            {editingTaskId ? 'Update Task' : 'Save Task'}
          </Button>
          <Button onClick={() => {
            setShowTaskModal(false);
            setEditingTaskId(null);
            setNewTask({ name: '', details: '', status: 'Pending' });
          }} sx={{ mt: 2, fontSize: '0.875rem' }}>
            Cancel
          </Button>
        </Paper>
      </Modal>

      {/* Subtask Modal */}
      <Modal open={showSubtaskModal} onClose={() => setShowSubtaskModal(false)}>
        <Paper sx={{ p: 3, width: 400, mx: 'auto', mt: '20vh', backgroundColor: theme.palette.background.paper }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary, mb: 2 }}>Add New Subtask</Typography>
          <TextField
            fullWidth
            name="name"
            label="Subtask Name"
            value={newTask.name}
            onChange={(e) => handleInputChange(e, setNewTask)}
            margin="dense"
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="details"
            label="Subtask Details"
            value={newTask.details}
            onChange={(e) => handleInputChange(e, setNewTask)}
            margin="dense"
            multiline
            rows={4}
            required
            sx={{ mb: 2 }}
          />
          <Select
            fullWidth
            name="status"
            value={newTask.status}
            onChange={(e) => handleInputChange(e, setNewTask)}
            margin="dense"
            required
            sx={{ mb: 2 }}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="In-progress">In-progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="On-Hold">On-Hold</MenuItem>
          </Select>
          <Button onClick={() => handleAddOrUpdateTask(currentTask.id)} variant="contained" color="primary" sx={{ mt: 2, fontSize: '0.875rem' }}>
            Save Subtask
          </Button>
          <Button onClick={() => {
            setShowSubtaskModal(false);
            setNewTask({ name: '', details: '', status: 'Pending' });
          }} sx={{ mt: 2, fontSize: '0.875rem' }}>
            Cancel
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
}

export default TaskTable;
