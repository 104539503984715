import React, { useState, useEffect, useRef } from 'react';
import { Box, Tabs, Tab, IconButton, Typography, Modal, TextField, Button, Select, MenuItem } from '@mui/material';
import { getFirestore, collection, addDoc, query, where, getDocs, doc, updateDoc,setDoc, getDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth'; 
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { onSnapshot } from 'firebase/firestore';

const CalendarComponent = () => {
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [view, setView] = useState('multiMonthYear');
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCalendarName, setNewCalendarName] = useState('');
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [eventModalType, setEventModalType] = useState('add');
  const [eventDetails, setEventDetails] = useState({ id: '', title: '', start: '', end: '', tds: [], isScheduledInspectionCalendar: false });
  const [tds, setTds] = useState([]); // State to store TDs
  const [aircraftTypes, setAircraftTypes] = useState([]); // State to store aircraft types
  const [selectedAircraft, setSelectedAircraft] = useState(''); // State for selected aircraft type
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete modal
  const { user, loading } = useAuth();
  const db = getFirestore();

  const aircraftColorMapRef = useRef({});
  const defaultColors = ['#ff7f50', '#6a5acd', '#20b2aa', '#ff6347', '#4682b4', '#9acd32'];

  

  useEffect(() => {
    const fetchAndListenCalendars = async () => {
      if (user && user.uid) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        const locationId = userData?.locationId;
        const buildingId = userData?.buildingId;

        if (!locationId || !buildingId) {
          console.error('User does not have locationId or buildingId defined.');
          return;
        }

        // Fetch aircraft types for filtering
        const aircraftQuery = query(
          collection(db, 'aircraft'),
          where('locationId', '==', locationId),
          where('buildingId', '==', buildingId)
        );
        const aircraftSnapshot = await getDocs(aircraftQuery);
        const aircraftSet = new Set();
        let colorIndex = Object.keys(aircraftColorMapRef.current).length;

        aircraftSnapshot.forEach(doc => {
          const { aircraftNumber } = doc.data();
          const type = aircraftNumber.split(' ')[0];

          aircraftSet.add(type);

          if (!aircraftColorMapRef.current[type]) {
            aircraftColorMapRef.current[type] = defaultColors[colorIndex % defaultColors.length];
            colorIndex++;
          }
        });

        setAircraftTypes(Array.from(aircraftSet));

        // Fetch personal calendars
        const personalCalendarsQuery = query(collection(db, 'calendars'), where('userId', '==', user.uid));
        const personalCalendarsSnapshot = await getDocs(personalCalendarsQuery);
        const personalCalendars = personalCalendarsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch the Scheduled Inspections calendar for the location and building
        const scheduledInspectionsCalendar = await fetchOrCreateScheduledInspectionsCalendar(locationId, buildingId);

        // Combine the personal calendars and the shared "Scheduled Inspections" calendar
        const allCalendars = [...personalCalendars, scheduledInspectionsCalendar];

        setCalendars(allCalendars);

        // Set the selected calendar to the first one available
        if (allCalendars.length > 0) {
          setSelectedCalendar(allCalendars[0].id);
        }

        // Set up a listener on the `aircraft` collection
        const unsubscribe = onSnapshot(aircraftQuery, async (snapshot) => {
          if (!snapshot.empty) {
            await populateInspections(scheduledInspectionsCalendar.id, locationId, buildingId);
          }
        });

        return unsubscribe; // Return the unsubscribe function to clean up
      }
      return () => {}; // Return a no-op function if user isn't available or if the function exits early
    };

    const unsubscribe = fetchAndListenCalendars();

    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [db, user]);

  useEffect(() => {
    // Fetch TDs from Firestore
    const fetchTds = async () => {
      const tdSnapshot = await getDocs(collection(db, 'technicalDirectives'));
      const tdList = tdSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTds(tdList);
    };

    fetchTds();
  }, [db]);

  useEffect(() => {
    if (selectedCalendar) {
      const calendarRef = doc(db, 'calendars', selectedCalendar);

      const unsubscribe = onSnapshot(calendarRef, (snapshot) => {
        if (snapshot.exists()) {
          const events = snapshot.data().events || [];
          let filteredEvents = events;

          // Filter events based on selected aircraft type
          if (selectedAircraft) {
            filteredEvents = events.filter(event =>
              event.title.startsWith(selectedAircraft)
            );
          }

          const formattedEvents = filteredEvents.map((event) => {
            const aircraftType = event.title.split(' ')[0];
            return {
              ...event,
              start: event.start.toDate(),
              end: event.end.toDate(),
              color: aircraftColorMapRef.current[aircraftType], // Assign color based on aircraft type
            };
          });

          setCalendarEvents(formattedEvents);
        } else {
          setCalendarEvents([]);
        }
      });

      return () => unsubscribe(); // Cleanup listener on unmount or calendar change
    }
  }, [db, selectedCalendar, selectedAircraft]);

  let isCreatingCalendar = false;

  const fetchOrCreateScheduledInspectionsCalendar = async (locationId, buildingId) => {
      if (isCreatingCalendar) {
          // If a creation process is already ongoing, wait for it to complete.
          await new Promise(resolve => {
              const interval = setInterval(() => {
                  if (!isCreatingCalendar) {
                      clearInterval(interval);
                      resolve();
                  }
              }, 100);
          });
      }
  
      try {
          // Set the lock to prevent concurrent calendar creation.
          isCreatingCalendar = true;
  
          // Query to check if the calendar already exists
          const q = query(
              collection(db, 'calendars'),
              where('locationId', '==', locationId),
              where('buildingId', '==', buildingId),
              where('type', '==', 'Scheduled Inspections')
          );
  
          const querySnapshot = await getDocs(q);
  
          if (!querySnapshot.empty) {
              console.log('Scheduled Inspections calendar already exists.');
              const docSnapshot = querySnapshot.docs[0];
              return { id: docSnapshot.id, ...docSnapshot.data() };
          } else {
              // If no calendar exists, create a new one
              console.log('Creating a new Scheduled Inspections calendar.');
  
              const newCalendarData = {
                  name: 'Scheduled Inspections',
                  locationId,
                  buildingId,
                  type: 'Scheduled Inspections',
                  createdAt: new Date(),
                  events: [],
                  nonDeletable: true
              };
  
              const calendarDocRef = await addDoc(collection(db, 'calendars'), newCalendarData);
              await populateInspections(calendarDocRef.id, locationId, buildingId);
  
              console.log('Scheduled Inspections calendar created successfully.');
              return { id: calendarDocRef.id, ...newCalendarData };
          }
      } catch (error) {
          console.error('Error fetching or creating Scheduled Inspections calendar:', error);
          throw error;
      } finally {
          // Release the lock
          isCreatingCalendar = false;
      }
  };
  



  const populateInspections = async (calendarId, locationId, buildingId) => {
    try {
        const calendarRef = doc(db, 'calendars', calendarId);
        const calendarSnap = await getDoc(calendarRef);

        if (calendarSnap.exists()) {
            const existingEvents = calendarSnap.data().events || [];

            const inspectionsQuery = query(
                collection(db, 'aircraft'),
                where('locationId', '==', locationId),
                where('buildingId', '==', buildingId)
            );
            const aircraftSnapshot = await getDocs(inspectionsQuery);

            const newEvents = [];
            const currentDate = new Date();

            for (const aircraftDoc of aircraftSnapshot.docs) {
                const aircraftData = aircraftDoc.data();
                const { aircraftNumber, inspections } = aircraftData;
                const aircraftType = aircraftNumber.split(' ')[0];

                if (Array.isArray(inspections)) {
                    for (const inspection of inspections) {
                        const { dueDate, inspectionId } = inspection;
                        const endDate = new Date(dueDate);
                        endDate.setUTCHours(23, 59, 59, 999);  // Set to end of the day

                        const inspectionTypeDoc = await getDoc(doc(db, 'inspectionTypes', inspectionId));
                        if (inspectionTypeDoc.exists()) {
                            const { recurrence, name, duration } = inspectionTypeDoc.data();
                            const eventDuration = parseInt(duration) || 1;

                            // Calculate the start date based on the duration
                            const startDate = new Date(endDate);
                            startDate.setDate(startDate.getDate() - eventDuration + 1); // Correctly calculate start date

                            for (let i = 0; i < 365 / recurrence; i++) {
                                const eventDate = new Date(startDate);
                                eventDate.setDate(eventDate.getDate() + i * recurrence);

                                if (eventDate >= currentDate) {
                                    const eventEndDate = new Date(eventDate);
                                    eventEndDate.setDate(eventEndDate.getDate() + eventDuration - 1);

                                    const newEvent = {
                                        id: `${aircraftNumber}-${inspectionId}-${eventDate.getTime()}`,
                                        title: `${aircraftNumber} ${name}`,
                                        start: Timestamp.fromDate(eventDate),
                                        end: Timestamp.fromDate(eventEndDate),
                                        color: aircraftColorMapRef.current[aircraftType] || '#378006',
                                        calendarType: 'Scheduled Inspections',
                                        tds: [],
                                    };

                                    if (!existingEvents.find(e => e.id === newEvent.id)) {
                                        newEvents.push(newEvent);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (newEvents.length > 0) {
                const updatedEvents = [...existingEvents, ...newEvents];
                await updateDoc(calendarRef, { events: updatedEvents });
                setCalendarEvents(updatedEvents.map(event => ({
                    ...event,
                    start: event.start.toDate(),
                    end: event.end.toDate(),
                })));
            }
        }
    } catch (error) {
        console.error('Error populating inspections:', error);
    }
};

  

  const handleCalendarChange = (event, newValue) => {
    setSelectedCalendar(newValue);
  };

  const handleAddCalendar = () => {
    setIsModalOpen(true);
  };

  const handleSaveCalendar = async () => {
    if (!user || !user.uid || newCalendarName.trim() === '') return;

    const calendarData = {
      name: newCalendarName,
      userId: user.uid,
      createdAt: new Date(),
      events: [],
    };

    try {
      const docRef = await addDoc(collection(db, 'calendars'), calendarData);
      setCalendars([...calendars, { id: docRef.id, ...calendarData }]);
      setSelectedCalendar(docRef.id);
      setIsModalOpen(false);
      setNewCalendarName('');
    } catch (error) {
      console.error("Error adding calendar: ", error);
    }
  };

  const handleEventDrop = async (info) => {
    const { event } = info;
    if (!selectedCalendar) return;

    try {
      const calendarRef = doc(db, 'calendars', selectedCalendar);
      const calendarSnap = await getDoc(calendarRef);

      if (calendarSnap.exists()) {
        const events = calendarSnap.data().events || [];
        const eventIndex = events.findIndex(e => e.id === event.id);

        if (eventIndex !== -1) {
          const originalEvent = events[eventIndex];
          const delta = event.start - originalEvent.start.toDate();

          const [aircraftNumber, ...inspectionTypeParts] = event.title.split(' ');
          const inspectionType = inspectionTypeParts.join(' ');

          events[eventIndex] = {
            ...originalEvent,
            start: Timestamp.fromDate(new Date(event.start)),
            end: Timestamp.fromDate(new Date(event.end)),
          };

          for (let i = eventIndex + 1; i < events.length; i++) {
            if (events[i].title.startsWith(`${aircraftNumber} ${inspectionType}`)) {
              const newStart = new Date(events[i].start.toDate().getTime() + delta);
              const newEnd = new Date(events[i].end.toDate().getTime() + delta);
              events[i] = {
                ...events[i],
                start: Timestamp.fromDate(newStart),
                end: Timestamp.fromDate(newEnd),
              };
            }
          }

          await updateDoc(calendarRef, { events });
          setCalendarEvents(events.map((e, index) => ({
            ...e,
            start: e.start.toDate(),
            end: e.end.toDate(),
          })));
        }
      }
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };
  
  const handleEventClick = (info) => {
    const { event } = info;
    const isScheduledInspectionCalendar = event.extendedProps.calendarType === 'Scheduled Inspections';

    // Ensure event start and end dates are valid Date objects
    const startDate = event.start ? new Date(event.start) : null;
    let endDate = event.end ? new Date(event.end) : null;

    if (!startDate || isNaN(startDate.getTime())) {
        console.error('Event does not have a valid start date.');
        return;
    }

    // Handle the case where the end date is invalid or missing
    if (!endDate || isNaN(endDate.getTime())) {
        console.warn('Invalid or missing end date detected, setting it to the same as the start date');
        endDate = new Date(startDate);
        endDate.setUTCHours(23, 59, 59, 999); // Set to end of day for single-day events
    }

    const startISOString = startDate.toISOString().substring(0, 16);
    const endISOString = endDate.toISOString().substring(0, 16);

    // Calculate the event duration (ensure it's at least 1 day)
    const eventDuration = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

    console.log(`Event Clicked - Duration: ${eventDuration} days`);

    // Store the original duration of the event without TDs
    const originalDuration = eventDuration - (event.extendedProps.tds || []).reduce((total, tdId) => {
        const td = tds.find(t => t.id === tdId);
        return td ? total + parseInt(td.duration, 10) : total;
    }, 0);

    // Set event details and open modal
    setEventDetails({
        id: event.id,
        originalTitle: event.title,
        title: event.title,
        start: startISOString,
        end: endISOString,
        tds: event.extendedProps.tds || [],
        originalDuration: originalDuration > 0 ? originalDuration : 1, // Ensure at least 1 day
        isScheduledInspectionCalendar: isScheduledInspectionCalendar,
    });

    setIsEventModalOpen(true);
};



const handleSaveEvent = async () => {
    if (!selectedCalendar || !eventDetails.tds) return;

    const originalStartDate = new Date(eventDetails.start);
    if (isNaN(originalStartDate.getTime())) {
        console.error('Invalid start date.');
        return;
    }

    // Calculate total TD duration for current state
    const currentTds = eventDetails.tds;
    const currentTdDuration = currentTds.reduce((total, tdId) => {
        const td = tds.find(t => t.id === tdId);
        return td ? total + parseInt(td.duration, 10) : total;
    }, 0);

    // New total duration should be the original duration plus the current TDs' duration
    const totalDuration = eventDetails.originalDuration + currentTdDuration;
    console.log(`New Total Duration: ${totalDuration} days`);

    const newEndDate = new Date(originalStartDate);
    newEndDate.setDate(newEndDate.getDate() + totalDuration - 1);

    if (isNaN(newEndDate.getTime())) {
        console.error('Calculated new end date is invalid');
        return;
    }

    const aircraftType = eventDetails.title.split(' ')[0];
    const color = aircraftColorMapRef.current[aircraftType] || '#378006';

    const updatedEvent = {
        ...eventDetails,
        start: Timestamp.fromDate(originalStartDate),
        end: Timestamp.fromDate(newEndDate),
        color: color,
        calendarType: 'Scheduled Inspections',
    };

    try {
        const calendarRef = doc(db, 'calendars', selectedCalendar);
        const calendarSnap = await getDoc(calendarRef);

        if (calendarSnap.exists()) {
            let updatedEvents = calendarSnap.data().events || [];

            updatedEvents = updatedEvents.map(e => e.id === updatedEvent.id ? updatedEvent : e);

            await updateDoc(calendarRef, { events: updatedEvents });

            setCalendarEvents(updatedEvents.map(event => ({
                ...event,
                start: event.start.toDate(),
                end: event.end.toDate(),
                color: event.color,
            })));

            setIsEventModalOpen(false);
        } else {
            console.error('Calendar not found');
        }
    } catch (error) {
        console.error('Error updating event:', error);
    }
};

  

  const handleEventDelete = async () => {
    if (!selectedCalendar || !eventDetails.id) return;

    try {
      const calendarRef = doc(db, 'calendars', selectedCalendar);
      const calendarSnap = await getDoc(calendarRef);

      if (calendarSnap.exists()) {
        const updatedEvents = calendarSnap.data().events.filter(e => e.id !== eventDetails.id);

        await updateDoc(calendarRef, { events: updatedEvents });
        setCalendarEvents(updatedEvents.map((event) => ({
          ...event,
          start: event.start.toDate(),
          end: event.end.toDate(),
          color: aircraftColorMapRef.current[event.title.split(' ')[0]], 
        })));
      } else {
        console.error('Calendar not found');
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }

    setIsEventModalOpen(false);
  };

  function renderEventContent(eventInfo) {
    return (
      <div>
        <b>{eventInfo.event.title}</b>
      </div>
    );
  }

  const handleDeleteCalendar = async () => {
    if (!selectedCalendar) return;

    try {
      const calendarRef = doc(db, 'calendars', selectedCalendar);
      const calendarSnap = await getDoc(calendarRef);

      if (calendarSnap.exists() && calendarSnap.data().nonDeletable) {
        console.error("This calendar cannot be deleted.");
        return;
      }

      await deleteDoc(doc(db, 'calendars', selectedCalendar));
      setCalendars(calendars.filter(c => c.id !== selectedCalendar));
      setSelectedCalendar(calendars.length > 1 ? calendars[1].id : null);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting calendar:", error);
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">You must be logged in to create and view calendars.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ minWidth: '200px', borderRight: 1, borderColor: 'divider' }}>
        {calendars.length > 0 ? (
          <Tabs
            orientation="vertical"
            value={selectedCalendar}
            onChange={handleCalendarChange}
            sx={{ '& .MuiTab-root': { textAlign: 'left' } }}
          >
            {calendars.map((calendar) => (
              <Tab key={calendar.id} label={calendar.name} value={calendar.id} />
            ))}
          </Tabs>
        ) : (
          <Typography sx={{ p: 2 }}>You currently have no calendars.</Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleAddCalendar}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={openDeleteModal}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ p: 2, flexGrow: 1 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Filter by Aircraft</Typography>
          <Select
            fullWidth
            value={selectedAircraft}
            onChange={(e) => setSelectedAircraft(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">All Aircraft</MenuItem>
            {aircraftTypes.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </Box>
        {calendars.length > 0 && selectedCalendar ? (
          <>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
              initialView={view}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,multiMonthYear',
              }}
              events={calendarEvents}
              editable={true}
              droppable={true}
              eventDrop={handleEventDrop}
              eventClick={handleEventClick}
              eventColor="#378006"
              height="auto"
              contentHeight="auto"
              dayMaxEventRows={true}
              dayMaxEvents={true}
              displayEventTime={false}
              eventDisplay='block'
              eventcontent={renderEventContent}
            />
          </>
        ) : (
          <Typography sx={{ p: 2 }}>Please create a calendar to start planning.</Typography>
        )}
      </Box>

      {/* Calendar Modal */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Create a New Calendar
          </Typography>
          <TextField
            fullWidth
            label="Calendar Name"
            variant="outlined"
            value={newCalendarName}
            onChange={(e) => setNewCalendarName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleSaveCalendar}>
            Save
          </Button>
        </Box>
      </Modal>

{/* Event Modal */}
<Modal open={isEventModalOpen} onClose={() => setIsEventModalOpen(false)}>
    <Box
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
        }}
    >
        <Typography variant="h6" sx={{ mb: 2 }}>
            {eventDetails.isScheduledInspectionCalendar ? 'Scheduled Inspection Details' : 'Edit Event'}
        </Typography>

        {eventDetails.isScheduledInspectionCalendar ? (
            <>
                <Typography variant="subtitle1">Added Technical Directives:</Typography>
                <ul>
                    {eventDetails.tds.map(tdId => {
                        const td = tds.find(t => t.id === tdId);
                        return (
                            <li key={tdId}>
                                {td?.name} ({td?.duration} days)
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    onClick={() => {
                                        const updatedTds = eventDetails.tds.filter(id => id !== tdId);
                                        setEventDetails({
                                            ...eventDetails,
                                            tds: updatedTds,
                                        });
                                    }}
                                    sx={{ ml: 2 }}
                                >
                                    Remove
                                </Button>
                            </li>
                        );
                    })}
                </ul>

                <Select
                    fullWidth
                    multiple
                    value={eventDetails.tds}
                    onChange={(e) => setEventDetails({ ...eventDetails, tds: e.target.value })}
                    renderValue={(selected) => selected.map(tdId => tds.find(td => td.id === tdId)?.name).join(', ')}
                    sx={{ mb: 2 }}
                >
                    {tds.map(td => (
                        <MenuItem key={td.id} value={td.id}>
                            {td.name} ({td.duration} days)
                        </MenuItem>
                    ))}
                </Select>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => setIsEventModalOpen(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveEvent} variant="contained" color="primary">
                        Save
                    </Button>
                </Box>
            </>
        ) : (
            <>
                <TextField
                    fullWidth
                    label="Event Title"
                    variant="outlined"
                    value={eventDetails.title}
                    onChange={(e) => setEventDetails({ ...eventDetails, title: e.target.value })}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label="Start Date"
                    type="datetime-local"
                    value={eventDetails.start}
                    onChange={(e) => setEventDetails({ ...eventDetails, start: e.target.value })}
                    sx={{ mb: 2 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    fullWidth
                    label="End Date"
                    type="datetime-local"
                    value={eventDetails.end}
                    onChange={(e) => setEventDetails({ ...eventDetails, end: e.target.value })}
                    sx={{ mb: 2 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => setIsEventModalOpen(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleEventDelete} variant="contained" color="secondary">
                        Delete
                    </Button>
                    <Button onClick={handleSaveEvent} variant="contained" color="primary">
                        Save
                    </Button>
                </Box>
            </>
        )}
    </Box>
</Modal>



      {/* Delete Calendar Modal */}
      <Modal open={isDeleteModalOpen} onClose={closeDeleteModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Are you sure you want to delete this calendar?
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            This action cannot be undone and you will lose all events associated with this calendar.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDeleteCalendar}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CalendarComponent;
