import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { auth } from '../firebase';
import SignUp from './SignUp';
import SignIn from './SignIn';
import { AppBar, Toolbar, Button, Typography, IconButton, Box } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';

function NavBar({ toggleThemeMode, mode }) {
  const { user, userData, loading } = useAuth();
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const handleSignOut = () => {
    auth.signOut();
  };

  if (loading) return <Typography variant="body1" sx={{ p: 2 }}>Loading...</Typography>;

  return (
    <AppBar position="static" sx={{ backgroundColor: mode === 'light' ? 'primary.main' : 'primary.dark' }}>
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            flexGrow: 1,
            textDecoration: 'none',
            color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText',
            '&:hover': {
              color: 'secondary.main',
            },
          }}
        >
          OQL
        </Typography>
        {user ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {userData && userData.status === 'approved' && (
              <>
                <Button component={Link} to="/profile" sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
                  Profile
                </Button>
                <Button component={Link} to="/aircraft" sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
                  Aircraft
                </Button>
                {(userData.role === 'Admin' || userData.role === 'Building Admin') && (
                  <Button component={Link} to="/admin" sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
                    Admin Page
                  </Button>
                )}
                {(userData.role === 'Admin' || userData.role === 'Building Admin' || userData.role === 'Manager') && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button component={Link} to="/projects" sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
                      Projects
                    </Button>
                    <Button component={Link} to="/planning" sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
                      Planning
                    </Button>
                  </Box>
                )}
                <Button onClick={handleSignOut} sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
                  Sign Out
                </Button>
              </>
            )}
          </Box>
        ) : (
          <>
            <Button onClick={() => setShowSignUp(true)} sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
              Sign Up
            </Button>
            <Button onClick={() => setShowSignIn(true)} sx={{ color: mode === 'light' ? 'primary.contrastText' : 'secondary.contrastText' }}>
              Sign In
            </Button>
          </>
        )}
        <IconButton color="inherit" onClick={toggleThemeMode}>
          {mode === 'light' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
      </Toolbar>
      {showSignUp && <SignUp onClose={() => setShowSignUp(false)} />}
      {showSignIn && <SignIn onClose={() => setShowSignIn(false)} />}
    </AppBar>
  );
}

export default NavBar;
