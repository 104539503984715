import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Box, Grid, Card, CardContent, Typography, CardActions, Button, useTheme } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { Link as RouterLink } from 'react-router-dom';

function Aircraft() {
  const { userData } = useAuth(); // Assume that userData contains locationId and buildingId
  const [aircraftList, setAircraftList] = useState([]);
  const theme = useTheme(); // Access the current theme

  useEffect(() => {
    const fetchAircraft = async () => {
      if (!userData?.locationId || !userData?.buildingId) return;

      const q = query(
        collection(db, 'aircraft'),
        where('locationId', '==', userData.locationId),
        where('buildingId', '==', userData.buildingId)
      );

      const querySnapshot = await getDocs(q);
      const aircraftArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAircraftList(aircraftArray);
    };

    fetchAircraft();
  }, [userData]);

  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme background color
        color: theme.palette.text.primary, // Theme text color
        minHeight: '100vh', // Ensure full height for the component
      }}
    >
      <Grid container spacing={3}>
        {aircraftList.map(aircraft => (
          <Grid item xs={12} sm={6} md={4} key={aircraft.id}>
            <Card
              elevation={3}
              sx={{
                backgroundColor: theme.palette.background.paper, // Theme paper background color
                color: theme.palette.text.primary, // Theme text color
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {aircraft.aircraftNumber}
                </Typography>
                <Typography color="textSecondary">{aircraft.aircraftType}</Typography>
                <Typography color="textSecondary">{aircraft.aircraftModel}</Typography>
                <Typography color="textSecondary">{aircraft.locationId}</Typography>
                <Typography color="textSecondary">{aircraft.buildingId}</Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" component={RouterLink} to={`/aircraft/${aircraft.id}`}>
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Aircraft;
