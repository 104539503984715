import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { Box, Button, TextField, Typography, Alert, Modal, Paper, useTheme } from '@mui/material';

function SignIn({ onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme(); // Access the current theme

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      const userData = userDoc.data();
      
      if (userData.status === 'approved') {
        navigate('/profile');
        onClose();
      } else if (userData.status === 'denied') {
        setError('Sorry, your account has been denied.');
      } else {
        setError('Your account is pending approval. Please wait for an admin to approve your account.');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box 
        component={Paper} 
        sx={{ 
          p: { xs: 3, md: 4 }, // Responsive padding
          maxWidth: 400, 
          mx: 'auto', 
          my: '20vh', 
          borderRadius: 2, 
          backgroundColor: theme.palette.background.paper, // Theme-based background color
        }}>
        <Typography variant="h4" gutterBottom>
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSignIn}>
          <TextField
            fullWidth
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            sx={{ mb: 2 }} // Margin bottom for spacing
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
            sx={{ mb: 2 }} // Margin bottom for spacing
          />
          <Button 
            fullWidth 
            type="submit" 
            variant="contained" 
            color="primary" 
            sx={{ mt: 2 }}>
            Sign In
          </Button>
        </Box>
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        <Button onClick={onClose} sx={{ mt: 2, color: theme.palette.text.secondary }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default SignIn;
