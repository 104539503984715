import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import getLPTheme from './theme/getLPTheme';

import NavBar from './components/NavBar';
import Dashboard from './pages/Dashboard';
import AdminPage from './pages/AdminPage';
import UserProfile from './pages/UserProfile';
import ProjectsPage from './pages/ProjectsPage';
import CallLog from './pages/CallLog';
import Shops from './pages/Shops';
import Employees from './pages/Employees';
import { withAuth } from './components/authUtils';
import ProjectDetail from './components/ProjectDetail';
import AircraftPage from './pages/AircraftPage';
import AircraftDetails from './components/AircraftDetails';
import ProtectedApp from './components/ProtectedApp';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Import from the correct module
import CssBaseline from '@mui/material/CssBaseline';
import PlanningPage from './pages/PlanningPage';
import AircraftInspections from './components/AircraftInspections';

const ProtectedProjectsPage = withAuth(ProjectsPage, ['Manager', 'Admin'], 'approved');
const ProtectedProjectDetail = withAuth(ProjectDetail, ['Manager', 'Admin'], 'approved');
const ProtectedPlanningPage = withAuth(PlanningPage, ['Manager', 'Admin'], 'approved');

function App() {
  const [mode, setMode] = useState('light');

  const toggleThemeMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(() => {
    return createTheme(getLPTheme(mode)); // Correct useMemo dependency array
  }, [mode]);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}> {/* Use 'theme' prop, not 'value' */}
        <CssBaseline /> {/* Ensures consistent styling */}
        <Router>
          <div className="App">
            <NavBar toggleThemeMode={toggleThemeMode} mode={mode} />
            <ProtectedApp>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/projects" element={<ProtectedProjectsPage />} />
                <Route path="/projects/:projectId" element={<ProtectedProjectDetail />} />
                <Route path="/aircraft" element={<AircraftPage />} />
                <Route path="/aircraft/:aircraftId" element={<AircraftDetails />} />
                <Route path="/call-log" element={<CallLog />} />
                <Route path="/shops/:locationId/:buildingId" element={<Shops />} />
                <Route path="/shops/:locationId/:buildingId/:shopId/employees" element={<Employees />} />
                <Route path="/planning" element={<ProtectedPlanningPage />} />
                <Route path="/aircraft/:aircraftId/inspections" element={<AircraftInspections />} />
              </Routes>
            </ProtectedApp>
          </div>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
