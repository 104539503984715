import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import UserInformation from '../components/UserInformation'; // Ensure the path is correct
import { withAuth } from '../components/authUtils'; // Ensure the path is correct

function UserProfile({ userData }) {
  const theme = useTheme(); // Access the current theme

  return (
    <Box
      sx={{
        mt: 4,
        p: { xs: 2, md: 4 }, // Responsive padding
        backgroundColor: theme.palette.background.default, // Theme background color
        color: theme.palette.text.primary, // Theme text color
        minHeight: 'max-content', // Ensure full height for the page
      }}
    >
      {/* Centered Header */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" gutterBottom>User Profile</Typography>
      </Box>

      {/* Left-aligned User Information */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          ml: { xs: 2, md: 4 }, // Responsive margin-left
          maxWidth: '100%',
        }}
      >
        <UserInformation userData={userData} />
      </Box>
    </Box>
  );
}

export default withAuth(UserProfile, [], 'approved');
