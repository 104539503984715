import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Ensure this path correctly points to your firebase config file

function ProtectedApp({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (!currentUser) {
        navigate('/'); // Redirect to the dashboard if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return <>{children}</>;
}

export default ProtectedApp;
