import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CalendarComponent from '../components/CalendarComponent';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

const PlanningPage = () => {
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const { currentUser } = useAuth();
  const db = getFirestore();

  const fetchEvents = async (calendarId) => {
    const eventQuery = query(
      collection(db, `calendars/${calendarId}/events`)
    );
    const eventSnapshot = await getDocs(eventQuery);
    const eventData = eventSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setEvents(eventData);
  };

  useEffect(() => {
    const fetchCalendars = async () => {
      if (currentUser) {
        const calendarQuery = query(
          collection(db, 'calendars'),
          where('userId', '==', currentUser.uid)
        );
        const calendarSnapshot = await getDocs(calendarQuery);
        const calendarData = calendarSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCalendars(calendarData);

        if (calendarData.length > 0) {
          await fetchEvents(calendarData[0].id); // Load events for the first calendar by default
        }
      }
    };

    fetchCalendars();
  }, [db, currentUser]);

  const handleCalendarChange = async (calendarId) => {
    await fetchEvents(calendarId);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        My Planning Calendar
      </Typography>
      <CalendarComponent
        events={events}
        calendars={calendars}
        onCalendarChange={handleCalendarChange}
        onAddCalendar={() => {}}
        onEventSelect={(event) => console.log(event)}
        onEventAdd={(slotInfo) => console.log(slotInfo)}
      />
    </Box>
  );
};

export default PlanningPage;
